import React, { Component } from 'react';
import { Layout } from "containers/Layout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { action as voiceAction } from "redux/actions/voice/voice_actions";
import { selector as voiceSelector } from "redux/reducers/voice/voice_reducer";
import { Transcribe } from 'components/Transcribe/Transcribe';

import LABELS from "labels";

import "./VoiceFromLocal.scss";

const {
  VOICE_FROM_LOCAL: {
    PAGE_TITLE
  }
} = LABELS;

class VoiceContainer extends Component {

  state = {
    isRecording: false,
    fileUrl: '',
    fileName: ''
  };


  getTranscribedText = () => {
    this.setState({
      isRecording: true
    });

    const {
      voiceAction: { getText },
    } = this.props;

    getText();
  }

  render() {
    const { isRecording } = this.state;
    const { transcribedText } = this.props;
    return (
      <Layout
        id="voice"
        layoutClass="layout"
        pageTitle={PAGE_TITLE}
      >
        <Transcribe
          isRecording={isRecording}
          transcribedText={transcribedText}
          showStop={false}
          startRecording={() => this.getTranscribedText()}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  transcribedText: voiceSelector.getTextResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
  voiceAction: bindActionCreators({ ...voiceAction }, dispatch),
});

export const VoiceFromLocal = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoiceContainer);
