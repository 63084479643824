import React, { Component } from 'react';
import { Layout } from "containers/Layout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { action as uploadLocalAction } from "redux/actions/upload/local_upload_actions";
import { selector as uploadLocalSelector } from "redux/reducers/upload/local_upload_reducer";
import { MediaRecorder as ExtendableMediaRecorder, register } from 'extendable-media-recorder';
import { connect as wavConnect } from 'extendable-media-recorder-wav-encoder';
import { selector as voiceSelector } from "redux/reducers/voice/voice_reducer";
import { v4 as uuidv4 } from 'uuid';
import LABELS from "labels";

import "./VoiceLocalUpload.scss";
import { Transcribe } from 'components/Transcribe/Transcribe';

let recorder = '';
const {
  VOICE_LOCAL_UPLOAD: {
    PAGE_TITLE
  }
} = LABELS;

const pcmEncodeChunk = (chunk) => {
  var offset = 0;
  var buffer = new ArrayBuffer(chunk.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < chunk.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, chunk[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return Buffer.from(buffer);
};

class VoiceLocalContainer extends Component {

  state = {
    isRecording: false,
    fileUrl: '',
    fileName: ''
  };

  startRecording = () => {
    this.setState({
      isRecording: true
    });

    
    (async() => {
      await register(await wavConnect());
    })();

    // request permission to access audio stream
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      // store streaming data chunks in array
      const chunks = [];
      // create media recorder instance to initialize recording
      recorder = new ExtendableMediaRecorder(stream, { mimeType: 'audio/wav' });
      // function to be called when data is received
      recorder.ondataavailable = e => {
        // console.log(e);
        // add stream data to chunks
        chunks.push(e.data);
        // if recorder is 'inactive' then recording has finished
        if (recorder.state === 'inactive') {
          // convert stream data chunks to a 'webm' audio format as a blob
          const blob = new Blob(chunks, { type: 'audio/wav' });

          // convert blob to URL so it can be assigned to a audio src attribute
          // TODO - remove this post successful working from here
          const fileUrl = URL.createObjectURL(blob);
          this.setState({
            fileUrl
          });
          // to here

          const {
            uploadLocalAction: { upload },
          } = this.props;
          const fileName = `${uuidv4()}.wav`;
          this.setState({
            fileName
          });
          const file = new File([blob], fileName, { type: blob.type });

          // upload file to S3
          upload(file);

        }
      };
      // start recording with 1 second time between receiving 'ondataavailable' events
      recorder.start(1000);
    }).catch(console.error);
  }

  stopRecording = () => {
    // this will trigger one final 'ondataavailable' event and set recorder state to 'inactive'
    recorder.stop();
    this.setState({
      isRecording: false
    });
  }

  render() {
    const { isRecording, fileUrl, fileName } = this.state;
    const { transcribedText } = this.props;
    return (
      <Layout
        id="voice"
        layoutClass="layout"
        pageTitle={PAGE_TITLE}
      >
        <Transcribe
          isRecording={isRecording}
          transcribedText={transcribedText}
          fileName={fileName}
          fileUrl={fileUrl}
          showStop={true}
          startRecording={() => this.startRecording()}
          stopRecording={() => this.stopRecording()}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadStatus: uploadLocalSelector.getUploadStatus(state),
  transcribedText: voiceSelector.getTextResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
  uploadLocalAction: bindActionCreators({ ...uploadLocalAction }, dispatch),
});

export const VoiceLocalUpload = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoiceLocalContainer);
