import React from "react";

import "./Transcribe.scss";

export const Transcribe = (props) => {

  const {
    isRecording,
    transcribedText,
    fileName,
    fileUrl,
    showStop,
    startRecording,
    stopRecording
  } = props;
  return (
    <div className="transcribe__style">
      <div>
        <textarea rows="10" cols="100" value={transcribedText} placeholder="Your transcribe text will appear here" />
        <div>
          <button disabled={isRecording} onClick={startRecording}>
            START
          </button>
          {
            showStop &&
            <button disabled={!isRecording} onClick={stopRecording}>
              STOP
            </button>

          }
          {
            !isRecording && !!fileName && !!fileUrl && <a href={fileUrl} download={fileName}>Download</a>
          }
        </div>
      </div>
    </div>
  );
}