// React
import LABELS from "labels";
import { Layout } from "containers/Layout";
import { WorkspaceLinks } from "components/WorkspaceLinks/WorkspaceLinks";
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Workspace.scss";

export class Workspace extends React.PureComponent {

  render() {
    return (
      <Layout
        id="workspace"
        layoutClass="layout"
      >
        <Container fluid>
          <Row>
            <Col md={6}>
              <div className="workspace__label__position">
                <span className="workspace__label">{LABELS.WORKSPACE.PAGE_TITLE}</span>
              </div>
              <NavLink to="/s3Upload">
                <WorkspaceLinks 
                  labelName={LABELS.VOICE_S3_UPLOAD.PAGE_TITLE}
                  labelPopupInfo={LABELS.VOICE_S3_UPLOAD.POPUP_INFO}
                />
              </NavLink>
              <NavLink to="/localUpload">
                <WorkspaceLinks 
                  labelName={LABELS.VOICE_LOCAL_UPLOAD.PAGE_TITLE}
                  labelPopupInfo={LABELS.VOICE_LOCAL_UPLOAD.POPUP_INFO}
                />
              </NavLink>
              <NavLink to="/fromLocal">
                <WorkspaceLinks 
                  labelName={LABELS.VOICE_FROM_LOCAL.PAGE_TITLE}
                  labelPopupInfo={LABELS.VOICE_FROM_LOCAL.POPUP_INFO}
                />
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
