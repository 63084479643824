import React from "react";

import "./WorkspaceLinks.scss";

export const WorkspaceLinks = (props) => {

  return (
    <div className="workspace-link__style">
      <span className="workspace-link__label">{props.labelName}
      </span>
      <div className="ui icon workspace-link__info" 
        data-inverted=""
        data-tooltip={props.labelPopupInfo}
        data-position="bottom right">
        <i className="info icon circle black"></i>
      </div>
    </div>
  );
}