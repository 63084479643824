import { UPLOAD_LOCAL, SUCCESS, ERROR } from "constant";

export const name = "local_upload";

export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  uploadStatus: "",
  presignurlStatus: ""
};

// reducer selectors
export const selector = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getUploadStatus: (state) => state[name].uploadStatus,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_LOCAL.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        uploadStatus: ERROR
      };
    case UPLOAD_LOCAL.PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ""
      };
    case UPLOAD_LOCAL.SUCCESS:
      return {
        ...state,
        loading: false,
        uploadStatus: SUCCESS,
      };
    default:
      return state;
  }
}