export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";

export const UPLOAD_S3 = {
  PENDING: "UPLOAD_S3_PENDING",
  SUCCESS: "UPLOAD_S3_SUCCESS",
  FAILURE: "_UPLOAD_S3_FAILURE",
  STATUS: "UPLOAD_S3_STATUS"
};

export const UPLOAD_LOCAL = {
  PENDING: "UPLOAD_LOCAL_PENDING",
  SUCCESS: "UPLOAD_LOCAL_SUCCESS",
  FAILURE: "_UPLOAD_LOCAL_FAILURE",
  STATUS: "UPLOAD_LOCAL_STATUS"
};

export const PRESIGNURL = {
  PENDING: "PRESIGNURL_PENDING",
  FAILURE: "PRESIGNURL_FAILURE",
  SUCCESS: "PRESIGNURL_SUCCESS",
  STATUS: "PRESIGNURL_STATUS"
}

export const VOICE_TO_TEXT_STREAMING = {
  PENDING: "VOICE_TO_TEXT_STREAMING_PENDING",
  FAILURE: "VOICE_TO_TEXT_STREAMING_FAILURE",
  SUCCESS: "VOICE_TO_TEXT_STREAMING_SUCCESS",
  STATUS: "VOICE_TO_TEXT_STREAMING_STATUS"
}

export const BEARER = 'Bearer ';