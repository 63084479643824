import { doAxiosRequest } from "config-axios";
import { UPLOAD_S3, PRESIGNURL } from "constant";
// import { isResponseOk } from "util";
import CONFIG from "config";
import { action as voiceAction } from "redux/actions/voice/voice_actions";

const {
  API_URL: {
    GET_PRESIGNED
  },
  X_API_KEY: {
    NGEN
  }
} = CONFIG;

export const action = {

  upload: (file, folderName) => async (dispatch) => {
    const { name: fileName } = file;
    dispatch(presignurlPending());
    try {
      const presignurlAxiosConfig = {
        method: 'get',
        endpoint: GET_PRESIGNED(fileName, folderName),
        headers: {
          'x-api-key': NGEN
        }
      }
      const presignurlResponse = await doAxiosRequest(presignurlAxiosConfig);
      dispatch(presignurlSuccess(presignurlResponse.data));

      const { signedRequest } = presignurlResponse.data;

      const uploadS3AxiosConfig = {
        method: 'put',
        endpoint: signedRequest,
        file: file,
        headers: {
          'Content-Type': file.type,
          'customAuthHeader': true,
        }
      }

      dispatch(uploadS3Pending());
      try {
        const uploadS3Response = await doAxiosRequest(uploadS3AxiosConfig);
        dispatch(uploadS3Success(uploadS3Response));

        const { getText  } = voiceAction;
        dispatch(getText(folderName, fileName, "s3Upload"));
      } catch (error) {
        dispatch(uploadS3Failure(error?.message));
      }
    } catch (error) {
      dispatch(presignurlFailure(error?.message));
    }
  }
}

const uploadS3Pending = () => ({
  type: UPLOAD_S3.PENDING,
});

const uploadS3Success = (uploadResult) => ({
  type: UPLOAD_S3.SUCCESS,
  payload: uploadResult,
});

const uploadS3Failure = (errorMessage) => ({
  type: UPLOAD_S3.FAILURE,
  payload: errorMessage,
});


const presignurlPending = (payload) => ({
  type: PRESIGNURL.PENDING,
  payload
});

const presignurlSuccess = (response) => ({
  type: PRESIGNURL.SUCCESS,
  payload: response
});

const presignurlFailure = (payload) => ({
  type: PRESIGNURL.FAILURE,
  payload
});