const LABELS = {
  VOICE_S3_UPLOAD: {
    PAGE_TITLE: "Voice S3 Upload",
    POPUP_INFO: "Voice S3 Upload"
  },
  VOICE_LOCAL_UPLOAD: {
    PAGE_TITLE: "Voice Local Upload",
    POPUP_INFO: "Voice Local Upload"
  },
  VOICE_FROM_LOCAL: {
    PAGE_TITLE: "Voice From Local",
    POPUP_INFO: "Voice From Local"
  },
  WORKSPACE: {
    PAGE_TITLE: "InI Workspace",
  },
};

export default LABELS;
