import { PageBanner } from "components/PageBanner";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import "./Layout.scss";

export class LayoutContainer extends PureComponent {
  render = () => {
    const {
      id,
      children,
      pageTitle,
      layoutClass
    } = this.props;
    return (
        <div className="layout__page" id={id}>
          <Row noGutters>
            <Col>
              <Fragment>
                <PageBanner
                  pageTitle={pageTitle}
                />
                <Container fluid
                  className={`${layoutClass}`}
                >
                  {children}
                </Container>
              </Fragment>
            </Col>
          </Row>
        </div>
    );
  };
}

LayoutContainer.propTypes = {
  id: PropTypes.node.isRequired,
  children: PropTypes.node,
  layoutClass: PropTypes.string,
  pageTitle: PropTypes.string
};

LayoutContainer.defaultProps = {
  pageTitle: "",
  layoutClass: ""
};

export const Layout = withRouter(LayoutContainer);