import React, { PureComponent } from "react"; 
import history from "redux/history";
import { Provider } from "react-redux";
import auth from "redux/auth";
import store from "redux/store";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { Security, SecureRoute } from "@okta/okta-react";
import ImplicitCallbackWrapper from "components/ImplicitCallback/ImplicitCallback";
import CONFIG from "config";
import { Workspace } from "containers/Workspace";
import { VoiceS3Upload } from "containers/VoiceS3Upload";
import { VoiceLocalUpload } from "containers/VoiceLocalUpload";
import { VoiceFromLocal } from "containers/VoiceFromLocal";

export class App extends PureComponent {

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Security auth={auth}>
              <Switch>
                <SecureRoute
                  exact
                  path={CONFIG.UI_URL.WORKSPACE}
                  component={Workspace}
                />
                <SecureRoute
                  exact
                  path={CONFIG.UI_URL.S3_UPLOAD}
                  component={VoiceS3Upload}
                />
                <SecureRoute
                  exact
                  path={CONFIG.UI_URL.LOCAL_UPLOAD}
                  component={VoiceLocalUpload}
                />
                <SecureRoute
                  exact
                  path={CONFIG.UI_URL.FROM_LOCAL}
                  component={VoiceFromLocal}
                />
                <Route
                  path={CONFIG.OKTA.REDIRECT_URL}
                  component={ImplicitCallbackWrapper}
                />
              </Switch>
          </Security>
        </ConnectedRouter>
      </Provider>
    );
  }
}