import { Auth } from '@okta/okta-react';
import CONFIG from 'config';
import history from './history';

export default new Auth({
  history,
  issuer: CONFIG.OKTA.ISSUER,
  client_id: CONFIG.OKTA.CLIENT_ID,
  redirect_uri: `${window.location.origin}${CONFIG.OKTA.REDIRECT_URL}`
});
