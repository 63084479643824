import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "./PageBanner.scss";

export class Banner extends PureComponent {

  render() {
    const {
      pageTitle
    } = this.props;
    return (
      <Fragment>
        <Container className="banner" fluid>
          <div className="banner__breadcrumb">
            <Row noGutters>
              <Col md={12}>
                <a
                  href="/"
                  onClick={this.gotoHome}
                  className="link__home"
                  title="I&I Home"
                >
                  InI Home
                </a>
                {pageTitle && <h1 className="banner__title">{pageTitle}</h1>}               
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

Banner.propTypes = {
  pageTitle: PropTypes.string
};

Banner.defaultProps = {
  pageTitle: "",
};

export const PageBanner = withRouter(Banner);
