import { VOICE_TO_TEXT_STREAMING } from "constant";

export const name = "voice";

export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  textResponse: ""
};

// reducer selectors
export const selector = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getTextResponse: (state) => state[name].textResponse
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case VOICE_TO_TEXT_STREAMING.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case VOICE_TO_TEXT_STREAMING.PENDING:
      return {
        ...state,
        loading: true,
      };
    case VOICE_TO_TEXT_STREAMING.SUCCESS:
      return {
        ...state,
        loading: false,
        textResponse: action.payload,
      };
    default:
      return state;
  }
}